import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import remedialBg from '../images/background/pexels-anete-lusina-5240643.jpg'
import pregnancyBg from '../images/treatments/pexels-leah-kelley-618923.jpg'
import aromatherapyBg from '../images/treatments/pexels-rodnae-productions-6724507.jpg'

const TreatmentsStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 25vh;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(25rem, 100%), 1fr));

    gap: 4rem;
  }

  .shadow-drop-2-center:hover {
    animation: shadow-drop-2-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
  }
  @media (max-width: 640px) {
    .wrapper {
      grid-template-columns: minmax(30rem, 1fr);
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-2-28 10:7:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
  @keyframes shadow-drop-2-center {
    0% {
      transform: translateZ(0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      transform: translateZ(80px);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
`

const CardStyles = styled.div`
  height: 20rem;
  border-radius: 1rem;

  overflow: hidden;
  position: relative;

  display: inline-block;
  cursor: pointer;

  &:hover .inner-card,
  &:hover .inner-card {
    transform: scale(1.2);
  }

  .inner-card {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to right,
        var(--gradient-primary),
        var(--gradient-secondary)
      ),
      ${props => `url(${props.bg})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all 0.5s;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  h4 {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
`

export const Treatments = () => {
  return (
    <TreatmentsStyles>
      <h3>Treatments</h3>
      <div className="wrapper">
        {data.map((item, index) => {
          return (
            <CardStyles
              className="card shadow-drop-2-center center"
              key={`${item.name}-${index}`}
              bg={`${item.background}`}
            >
              <Link to="/treatments">
                <div className="inner-card">
                  <h4>{item.name}</h4>
                </div>
              </Link>
            </CardStyles>
          )
        })}
      </div>
    </TreatmentsStyles>
  )
}

const data = [
  {
    name: 'Pregnancy Massage',
    background: pregnancyBg,
  },
  {
    name: 'Remedial Massage',
    background: remedialBg,
  },
  {
    name: 'Aromatherapy Massage',
    background: aromatherapyBg,
  },
  // {
  //   name: 'Deep Tissue Massage',
  //   icon: `${deepTissueIcon}`,
  // },
  // {
  //   name: 'Relaxation Massage',
  // },
  // {
  //   name: 'Hot Stone Massage',
  // },
  // {
  //   name: 'Tibetan Singing Bowl Therapy Sounds Massage',
  // },
]

// Photo by RODNAE Productions from Pexels
// Photo by Leah Kelley from Pexels
